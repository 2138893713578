import React, { useEffect, useState } from 'react';
import { graphql } from 'relay-runtime';
import {
  PreloadedQuery,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import type {
  ChooseRoleQuery as ChooseRoleQueryType,
  ChooseRoleQuery$data as ChooseRoleQueryResponse,
} from './__generated__/ChooseRoleQuery.graphql';
import PageHeader, { PageHeaderGlimmer } from '../tenant_portal/PageHeader';
import listButtonStyles from '../../../stylesheets/components/listButtons.module.scss';
import layoutStyles from '../../../stylesheets/components/layout.module.scss';
import textStyles from '../../../stylesheets/components/text.module.scss';
import { ErrorType } from '../components/Types';
import { ChooseRoleLoginMutation$data } from './__generated__/ChooseRoleLoginMutation.graphql';
import { formatErrorMessages } from '../components/Tools';
import SubmitSpinner from '../components/SubmitSpinner';
import classNames from 'classnames';
import { TextFieldGlimmer } from '../tenant_portal/components/Text';
import Alert from '../components/Alert';

export const ChooseRoleQuery = graphql`
  query ChooseRoleQuery {
    login {
      availableLoginEntities {
        __id
        displayName
        loginRoleDescription
      }
    }
  }
`;

const LoginChooseRoleMutation = graphql`
  mutation ChooseRoleLoginMutation($input: LoginChooseRoleInput!) {
    loginChooseRole(input: $input) {
      redirectPath
      errors {
        path
        messages
      }
    }
  }
`;

const ChooseRole = () => {
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const baseErrors = errors.filter(
    (error) => error.path.split('.').slice(-1)[0] === 'base'
  );
  const formattedBaseErrorMessages = formatErrorMessages(baseErrors, '');

  const loaderQueryReference = useLoaderData() as PreloadedQuery<
    ChooseRoleQueryType,
    ChooseRoleQueryResponse
  >;

  const [queryReference] = useQueryLoader<ChooseRoleQueryType>(
    ChooseRoleQuery,
    loaderQueryReference
  );

  const chooseRoleData = usePreloadedQuery<ChooseRoleQueryType>(
    ChooseRoleQuery,
    queryReference as PreloadedQuery<
      ChooseRoleQueryType,
      ChooseRoleQueryResponse
    >
  );

  useEffect(() => {
    if (errors.length > 0) {
      console.log(errors);
    }
  }, [errors]);

  const [commitMutation, isMutationInFlight] = useMutation(
    LoginChooseRoleMutation
  );

  const handleSubmit = (entityId: string) => {
    commitMutation({
      variables: {
        input: {
          roleId: entityId,
        },
      },
      onCompleted: (response) => {
        const responseData = response as ChooseRoleLoginMutation$data;
        console.log('completed! response: ', responseData);
        if (!responseData || !responseData.loginChooseRole) {
          setErrors([
            {
              path: 'base',
              messages: [
                'There was an error. Please contact Property Matrix support for assistance.',
              ],
            },
          ]);
          return;
        }
        if (
          responseData.loginChooseRole.errors &&
          responseData.loginChooseRole.errors.length > 0
        ) {
          setErrors(responseData.loginChooseRole.errors as ErrorType[]);
          return;
        }
        if (responseData.loginChooseRole.redirectPath) {
          setIsRedirecting(true);
          window.location.href = responseData.loginChooseRole.redirectPath;
        } else {
          setErrors([
            {
              path: 'base',
              messages: [
                'There was an error. Please contact Property Matrix support for assistance.',
              ],
            },
          ]);
          return;
        }
      },
      onError: (error) => {
        setErrors([
          {
            path: 'base',
            messages: [
              'There was an error. Please contact Property Matrix support for assistance.',
            ],
          },
        ]);
        console.error(error);
      },
    });
  };

  if (isMutationInFlight || isRedirecting)
    return <SubmitSpinner spinnerText={'Redirecting...'} />;

  return (
    <>
      {baseErrors.length > 0 && (
        <Alert severity="error">{formattedBaseErrorMessages}</Alert>
      )}
      <PageHeader title="Select an account" />
      <ul
        data-test-class="choose-role-list"
        className={listButtonStyles.listButtons}
      >
        {chooseRoleData.login?.availableLoginEntities?.map((entity) => {
          return (
            <li key={entity.__id}>
              <button
                className={classNames(
                  layoutStyles.flex,
                  layoutStyles.alignCenter,
                  layoutStyles.spaceBetween,
                  layoutStyles.p16,
                  layoutStyles.fullWidth,
                  layoutStyles.border
                )}
                data-test-class="login-entity-button"
                onClick={() => {
                  handleSubmit(entity.__id);
                }}
              >
                <span
                  className={classNames(
                    layoutStyles.flex,
                    layoutStyles.flexColumn
                  )}
                >
                  <span
                    className={classNames(
                      layoutStyles.block,
                      layoutStyles.mb6,
                      textStyles.fs16,
                      textStyles.fwSb,
                      textStyles.colorMain
                    )}
                    data-test-class="entity-name"
                  >
                    {entity.displayName}
                  </span>
                  <span
                    className={classNames(
                      layoutStyles.block,
                      textStyles.fs14,
                      textStyles.colorSecondary
                    )}
                    data-test-class="entity-description"
                  >
                    {entity.loginRoleDescription}
                  </span>
                </span>
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.43506 12.9425L9.87756 9.5L6.43506 6.0575L7.50006 5L12.0001 9.5L7.50006 14L6.43506 12.9425Z"
                    fill="#1472FF"
                  ></path>
                </svg>
              </button>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export const ChooseRoleGlimmer = () => {
  return (
    <>
      <PageHeaderGlimmer />
      <TextFieldGlimmer />
      <TextFieldGlimmer />
      <TextFieldGlimmer />
      <TextFieldGlimmer />
    </>
  );
};

export default ChooseRole;
